import React from 'react';
import { Avatar } from './Avatar';
import './RoundsTable.scss';
import { GameContext } from './models/gameContext';

export function RoundsTable({ gameContext }: { gameContext: GameContext }): JSX.Element {
  const { activeRound, players, rounds } = gameContext;
  const roundsToShow = rounds.filter((round) => round.completedDate || round.roundSequence === gameContext.activeRoundSequence);

  const playersRanked = players.map((player) => {
    return {
      playerId: player.playerId,
      ranking: player.ranking,
      avatar: player.avatar,
      playerName: player.playerName,
      points: player.points,
    };
  });

  playersRanked.sort((a, b) => (a.ranking > b.ranking ? 1 : b.ranking > a.ranking ? -1 : 0));

  return (
    <table className="rounds-table table table-striped table-sm">
      <thead>
        <tr>
          <th>&nbsp;</th>
          {playersRanked.map((player) => (
            <th key={`leaderboard-header-` + player.playerId}>
              <Avatar avatar={player.avatar} name={player.playerName} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {roundsToShow.map((round) => {
          const rowClassName = activeRound.roundId === round.roundId ? 'leaderboard-row active-round' : 'leaderboard-row';

          return (
            <tr className={rowClassName} key={`leaderboard-row-` + round.roundId}>
              <td>{round.specification.description}</td>
              {playersRanked.map((player) => {
                const playerRound = round.roundPlayerLink.filter((x) => x.playerId === player.playerId)[0];

                if (round.completedDate) {
                  const className = playerRound.guess === playerRound.actual ? 'correct' : 'wrong';

                  return (
                    <td key={`leaderboard-row-` + round.roundSequence + player.playerId} className={className}>
                      <span>{playerRound.points}</span>
                    </td>
                  );
                } else {
                  return (
                    <td key={`leaderboard-row-` + round.roundSequence + player.playerId}>
                      <span>{playerRound.guess}</span>
                    </td>
                  );
                }
              })}
            </tr>
          );
        })}
      </tbody>
      {roundsToShow.length > 1 && (
        <tfoot>
          <tr className="total-row">
            <td>Total</td>
            {playersRanked.map((player) => {
              return <td key={`leaderboard-points-` + player.playerId}>{player.points}</td>;
            })}
          </tr>
        </tfoot>
      )}
    </table>
  );
}
