import React from 'react';
import './Leaderboard.scss';
import { GameContext } from './models/gameContext';
import { RightHeader } from './RightHeader';

export function Leaderboard({ gameContext }: { gameContext: GameContext }): JSX.Element {
  const { players, rounds } = gameContext;
  const hasCompletedRound = rounds.some((round) => round.completedDate);

  if (!hasCompletedRound) {
    return null;
  }

  const playersRanked = players.map((player) => {
    return {
      playerId: player.playerId,
      ranking: player.ranking,
      avatar: player.avatar,
      playerName: player.playerName,
      points: player.points,
    };
  });

  playersRanked.sort((a, b) => (a.ranking > b.ranking ? 1 : b.ranking > a.ranking ? -1 : 0));

  return (
    <div className="leaderboard">
      <RightHeader title="Leaderboard" />
      <table className="table table-striped">
        <tbody>
          {playersRanked.map((player) => (
            <tr key={`leaderboard-${player.playerId}`}>
              <td>{player.ranking}</td>
              <td>{player.points}</td>
              <td>{player.playerName}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
