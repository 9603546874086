import React from 'react';
import { Round } from './models/gameContext';

interface RoundSpecificationProps {
  round: Round;
}

export function RoundSpecification({ round }: RoundSpecificationProps): JSX.Element {
  return (
    <table className="round-specification table table-striped">
      <tbody>
        <tr>
          <td>Round Type</td>
          <td>{round.specification.description}</td>
        </tr>
        <tr>
          <td># Cards</td>
          <td>{round.specification.numCards}</td>
        </tr>
        <tr>
          <td>Points per trick</td>
          <td>{round.specification.pointsPerTrick}</td>
        </tr>
      </tbody>
    </table>
  );
}
